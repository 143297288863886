var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _vm.vueAppRunningProduction
      ? _c("div", { staticClass: "banner-testing-mode" })
      : _vm._e(),
    _vm.vueAppRunningProduction
      ? _c("div", { staticClass: "container-testing-mode" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(268, "Testing")) + "\n\t"),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "login justify-content-center" },
          [
            _c(
              "b-col",
              { staticClass: "form", attrs: { md: "6", sm: "6" } },
              [
                _c(
                  "b-card",
                  { staticClass: "p-4", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-body",
                      [
                        _c("img", {
                          staticClass: "navbar-brand-full",
                          attrs: {
                            src: "img/brand/thegreenshot-brand.png",
                            width: "120",
                            alt: "TheGreenShot",
                            align: "right",
                          },
                        }),
                        _c("br"),
                        _c("h2", [_vm._v(_vm._s(_vm.FormMSG(1, "Register")))]),
                        _c("p", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  102,
                                  "Free, no credit card required!"
                                )
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [
                                    _c(_vm.getLucideIcon("User"), {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.PERCENT.color,
                                        size: 18,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(6, "First name"),
                              },
                              model: {
                                value: _vm.userFirstName,
                                callback: function ($$v) {
                                  _vm.userFirstName = $$v
                                },
                                expression: "userFirstName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [
                                    _c(_vm.getLucideIcon("User"), {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.PERCENT.color,
                                        size: 18,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.FormMSG(5, "Last name"),
                              },
                              model: {
                                value: _vm.userName,
                                callback: function ($$v) {
                                  _vm.userName = $$v
                                },
                                expression: "userName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [
                                    _c(_vm.getLucideIcon("MessageSquare"), {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.PERCENT.color,
                                        size: 18,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionsForLanguages,
                                id: "optionsForLanguages",
                              },
                              model: {
                                value: _vm.userLanguage,
                                callback: function ($$v) {
                                  _vm.userLanguage = $$v
                                },
                                expression: "userLanguage",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: _vm.manageInputEmailCols } },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "b-input-group-prepend",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          [
                                            _c(_vm.getLucideIcon("AtSign"), {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.PERCENT.color,
                                                size: 18,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.emailValidated,
                                      },
                                      attrs: {
                                        type: "email",
                                        state: _vm.stateEmail,
                                        placeholder: _vm.FormMSG(7, "Email"),
                                        disabled: _vm.emailValidated,
                                        autocomplete: "email",
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function ($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.emailValidated
                              ? _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.$screen.width <= 567 ? 12 : 4,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          block: "",
                                          disabled:
                                            _vm.disableVerifyEmail ||
                                            _vm.loadingFor.verifyEmail,
                                        },
                                        on: { click: _vm.verifyEmail },
                                      },
                                      [
                                        _vm.dataToCheck.emailCodeValidation !==
                                          0 && !_vm.loadingFor.verifyEmail
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      45,
                                                      "Resend code"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        !_vm.loadingFor.verifyEmail &&
                                        _vm.dataToCheck.emailCodeValidation ===
                                          0
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      46,
                                                      "Verify email"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.loadingFor.verifyEmail
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      47,
                                                      "Check email"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.$screen.width <= 567 && _vm.showVerificationMail
                          ? _c("p", { staticClass: "fs-12" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      53,
                                      "Enter email verfication code"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm.showVerificationMail
                          ? _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c("b-col", { attrs: { cols: "8" } }, [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        horizontal: true,
                                        id: "fieldset-email-validation",
                                        "aria-labelledby":
                                          "aria-phone-validation",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "form-row" }, [
                                        _vm.$screen.width >= 471
                                          ? _c(
                                              "legend",
                                              {
                                                staticClass:
                                                  "col-8 bv-no-focus-ring col-form-label fs-12",
                                                attrs: {
                                                  tabindex: "-1",
                                                  id: "aria-email-validation",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        53,
                                                        "Enter email verfication code"
                                                      )
                                                    ) +
                                                    " :\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c("b-form-input", {
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.emailValidated,
                                              },
                                              attrs: {
                                                state:
                                                  _vm.stateError
                                                    .emailCodeValidation,
                                                placeholder: "ex: 123456",
                                                disabled: _vm.emailValidated,
                                              },
                                              on: {
                                                input:
                                                  _vm.handleInputEmailCodeValidation,
                                              },
                                              model: {
                                                value: _vm.emailCodeValidation,
                                                callback: function ($$v) {
                                                  _vm.emailCodeValidation = $$v
                                                },
                                                expression:
                                                  "emailCodeValidation",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _vm.stateError.emailCodeValidation !== null &&
                                _vm.stateError.emailCodeValidation === false
                                  ? _c(
                                      "b-col",
                                      {
                                        staticClass: "pt-2",
                                        attrs: { cols: "4" },
                                      },
                                      [
                                        _c("X", {
                                          attrs: { color: "#B11548", size: 20 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.stateError.emailCodeValidation === true
                                  ? _c(
                                      "b-col",
                                      {
                                        class:
                                          _vm.$screen.width <= 567
                                            ? "pt-2"
                                            : "pt-2",
                                        attrs: { cols: "4" },
                                      },
                                      [
                                        _c("Check", {
                                          attrs: { color: "#28a745", size: 20 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.emailValidated,
                                expression: "emailValidated",
                              },
                            ],
                          },
                          [
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      _vm.$screen.width < 567 ? "mb-3" : "",
                                    attrs: { cols: _vm.manageInputPhoneCols },
                                  },
                                  [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            { staticStyle: { height: "100%" } },
                                            [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  staticStyle: {
                                                    "border-radius":
                                                      "0.25rem 0 0 0.25rem",
                                                  },
                                                },
                                                [
                                                  _c("Phone", {
                                                    attrs: {
                                                      color: "#4d4e4f",
                                                      size: 16,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("VuePhoneNumberInput", {
                                            attrs: {
                                              "error-color": "#EA4E2C",
                                              "valid-color": "#00AF75",
                                              size: "sm",
                                              translations:
                                                _vm.optionsTranslation,
                                              disabled: _vm.phoneValidated,
                                              "default-country-code": "BE",
                                            },
                                            on: {
                                              update:
                                                _vm.handleUpdatePhoneNumber,
                                            },
                                            model: {
                                              value: _vm.phone,
                                              callback: function ($$v) {
                                                _vm.phone = $$v
                                              },
                                              expression: "phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                !_vm.phoneValidated
                                  ? _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          cols:
                                            _vm.$screen.width < 567 ? 12 : 4,
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary",
                                              block: "",
                                              disabled:
                                                _vm.disableVerifyPhone ||
                                                _vm.loadingFor.verifyPhone,
                                            },
                                            on: { click: _vm.verifyPhone },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(_vm.labelPhoneAction) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.$screen.width <= 567 &&
                            _vm.showVerificationPhone
                              ? _c("p", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          58,
                                          "Enter SMS verfication code"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm.showVerificationPhone
                              ? _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "8" } }, [
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass: "form-group",
                                          attrs: {
                                            horizontal: true,
                                            id: "fieldset-phone-validation",
                                            "aria-labelledby":
                                              "aria-phone-validation",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _vm.$screen.width >= 471
                                                ? _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "col-8 bv-no-focus-ring col-form-label",
                                                      attrs: {
                                                        tabindex: "-1",
                                                        id: "aria-phone-validation",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              58,
                                                              "Enter phone verfication code"
                                                            )
                                                          ) +
                                                          " :\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      state:
                                                        _vm.stateError
                                                          .phoneCodeValidation,
                                                      placeholder: "ex: 1234",
                                                      disabled:
                                                        _vm.phoneValidated,
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.handleInputPhoneCodeValidation,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.phoneCodeValidation,
                                                      callback: function ($$v) {
                                                        _vm.phoneCodeValidation =
                                                          $$v
                                                      },
                                                      expression:
                                                        "phoneCodeValidation",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm.stateError.phoneCodeValidation !==
                                      null &&
                                    _vm.stateError.phoneCodeValidation === false
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "pt-2",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c("X", {
                                              attrs: {
                                                color: "#B11548",
                                                size: 20,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.stateError.phoneCodeValidation === true
                                      ? _c(
                                          "b-col",
                                          {
                                            class:
                                              _vm.$screen.width <= 567
                                                ? "pt-2"
                                                : "pt-2",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c("Check", {
                                              attrs: {
                                                color: "#28a745",
                                                size: 20,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.emailValidated && _vm.phoneValidated,
                                    expression:
                                      "emailValidated && phoneValidated",
                                  },
                                ],
                                staticClass: "pb-3",
                              },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        on: {
                                          change:
                                            _vm.handleChangeCheckTermsOfService,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(81, "I accept")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "false-link",
                                            on: {
                                              click:
                                                _vm.handleClickTermsOfService,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  82,
                                                  "TheGreenshot's Terms of Service"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.emailValidated && _vm.phoneValidated,
                                expression: "emailValidated && phoneValidated",
                              },
                            ],
                            attrs: {
                              variant: "success",
                              block: "",
                              disabled:
                                this.disableSave || _vm.waitProcessRegistration,
                            },
                            on: { click: _vm.createAccount },
                          },
                          [
                            _vm.waitProcessRegistration
                              ? _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(8, "Create License Account")
                                ) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-success",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(1282, "Validation code"),
                  "ok-variant": "success",
                  "ok-only": "",
                },
                on: {
                  ok: function ($event) {
                    _vm.showModalInfoCode = false
                  },
                },
                model: {
                  value: _vm.showModalInfoCode,
                  callback: function ($$v) {
                    _vm.showModalInfoCode = $$v
                  },
                  expression: "showModalInfoCode",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.messageModalInfoCode) + "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-success",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(12, "Success!"),
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  "ok-variant": "success",
                  "ok-only": "",
                },
                on: { ok: _vm.handleClickOkAlreadyAccountExist },
                model: {
                  value: _vm.showModalAlreadyAccountExist,
                  callback: function ($$v) {
                    _vm.showModalAlreadyAccountExist = $$v
                  },
                  expression: "showModalAlreadyAccountExist",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        1295,
                        "You will be redirected to login, the system has seen that you already have an account which is not yet linked to a SOLO project. You can login with your current email and password"
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(1290, "Error"),
                  "ok-variant": "danger",
                  "ok-only": "",
                },
                on: {
                  ok: function ($event) {
                    _vm.showModalError = false
                  },
                },
                model: {
                  value: _vm.showModalError,
                  callback: function ($$v) {
                    _vm.showModalError = $$v
                  },
                  expression: "showModalError",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(1294, "You can no longer create an account.")
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-success",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(12, "Success!"),
                  "ok-variant": "success",
                  "ok-only": "",
                },
                on: { ok: this.callEnd },
                model: {
                  value: _vm.successModal,
                  callback: function ($$v) {
                    _vm.successModal = $$v
                  },
                  expression: "successModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\tAn e-mail containing a validation code has just been sent to " +
                    _vm._s(_vm.dataToCheck.email) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(428, "Error!"),
                  "ok-variant": "danger",
                  "ok-only": "",
                },
                on: { ok: this.callEnd },
                model: {
                  value: _vm.alreadyExistModal,
                  callback: function ($$v) {
                    _vm.alreadyExistModal = $$v
                  },
                  expression: "alreadyExistModal",
                },
              },
              [
                _c("h2", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          427,
                          `You already registered a single user free project!`
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: _vm.FormMSG(11, "Field validation error"),
                  "ok-variant": "danger",
                  "ok-only": "",
                },
                on: {
                  ok: function ($event) {
                    _vm.errorModal = false
                  },
                },
                model: {
                  value: _vm.errorModal,
                  callback: function ($$v) {
                    _vm.errorModal = $$v
                  },
                  expression: "errorModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        10,
                        "All fields must be filled with valid data!"
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-modal",
              {
                staticClass: "modal-danger",
                attrs: {
                  "header-class": "header-class-modal-doc-package",
                  title: "Email address invalid",
                  "ok-variant": "danger",
                  "ok-only": "",
                },
                on: {
                  ok: function ($event) {
                    _vm.errorEmailModal = false
                  },
                },
                model: {
                  value: _vm.errorEmailModal,
                  callback: function ($$v) {
                    _vm.errorEmailModal = $$v
                  },
                  expression: "errorEmailModal",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        13,
                        "Check the email address provided, it is invalid!"
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }